import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Menu } from './menu.model';
import { verticalMenuItems, horizontalMenuItems } from './menu';

@Injectable()
export class MenuService {

  constructor(private location: Location) { }

  public getVerticalMenuItems(): Array<Menu> {
    let currentUserRole = localStorage.getItem('currentUserRole');
    let menuArray = [];

    if (location.hostname == 'ltm.edulinkin.com' ||
      location.hostname == 'ltmstage.edulinkin.com' ||
      location.hostname == 'ltmisa.edulinkin.com' ||
      location.hostname == 'ltmisastage.edulinkin.com') {
      if (currentUserRole == "Teacher") {
        menuArray = [
          new Menu(1, 'Exams', '/exams', null, 'account_balance', null, false, 0),
          new Menu(2, 'Mock exams', '/mocks', null, 'poll', null, false, 0),
          new Menu(3, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
          // new Menu(4, 'Surveys', 'surveys/assigned-surveys', null, 'poll', null, false, 0),
          // new Menu(5, 'Evaluations', 'evaluations/targeted-evaluations', null, 'poll', null, false, 0),
        ]
      }
      else if (currentUserRole == "SubTeacher") {
        menuArray = [
          new Menu(1, 'Exams', 'exams/published-exams', null, 'card_membership', null, false, 0),
          new Menu(2, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "Supervisor") {
        menuArray = [
          new Menu(1, 'Exams', '/exams', null, 'account_balance', null, false, 0),
          new Menu(2, 'Mock exams', '/mocks', null, 'poll', null, false, 0),
          // new Menu(4, 'Surveys', 'surveys/assigned-surveys', null, 'poll', null, false, 0),
          // new Menu(2, 'Evaluations', 'evaluations/assigned-evaluations', null, 'poll', null, false, 0),
          // new Menu(2, 'Evaluations to targets', 'evaluations/assigned-targeted-evaluations', null, 'poll', null, false, 0),
          new Menu(3, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
          new Menu(4, 'Published exams correction', 'exams/published-exams-correction', null, 'account_balance', null, false, 0),
          new Menu(5, 'Exam index', 'exams/exam-index', null, 'summarize', null, false, 0),
          new Menu(5, 'Subject index', 'exams/subject-index', null, 'summarize', null, false, 0),
          //new Menu(7, 'Reports', 'reports/reports', null, 'computer', null, false, 0),
          new Menu(7, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(8, 'Results by categories', 'exams/results-by-categories', null, 'summarize', null, false, 7),
          new Menu(9, 'Ranges on assessment', 'exams/ranges-on-assessment', null, 'summarize', null, false, 7),
          new Menu(10, 'Ranges on skills', 'exams/ranges-on-skills', null, 'summarize', null, false, 7),
          new Menu(11, 'Ranges on gender', 'exams/ranges-on-gender', null, 'summarize', null, false, 7),
          new Menu(12, 'Ranges on nationality', 'exams/ranges-on-nationality', null, 'summarize', null, false, 7),
          new Menu(13, 'Students results', 'exams/students-results', null, 'summarize', null, false, 7),
          new Menu(14, 'Question answers count', 'exams/questions-answers-count', null, 'summarize', null, false, 6),
          new Menu(15, 'Report migration', '/ltm/report-migration', null, 'poll', null, false, 0)
        ]
      }
      else if (currentUserRole == "SubSupervisor") {
        menuArray = [
          new Menu(1, 'Published exams', 'exams/published-exams', null, 'account_balance', null, false, 0),
          new Menu(2, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
          new Menu(5, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(6, 'Results by categories', 'exams/results-by-categories', null, 'summarize', null, false, 5),
          new Menu(7, 'Ranges on assessment', 'exams/ranges-on-assessment', null, 'summarize', null, false, 5),
          new Menu(8, 'Ranges on skills', 'exams/ranges-on-skills', null, 'summarize', null, false, 5),
          new Menu(9, 'Ranges on gender', 'exams/ranges-on-gender', null, 'summarize', null, false, 5),
          new Menu(10, 'Ranges on nationality', 'exams/ranges-on-nationality', null, 'summarize', null, false, 5),
          new Menu(11, 'Students results', 'exams/students-results', null, 'summarize', null, false, 5),
          new Menu(12, 'Question answers count', 'exams/questions-answers-count', null, 'summarize', null, false, 5)
        ]
      }
      else if (currentUserRole == "Parent") {
        menuArray = [
          //new Menu(1, 'Surveys', 'surveys/assigned-surveys', null, 'poll', null, false, 0),
        ]
      }
      else if (currentUserRole == "Student") {
        menuArray = [
          new Menu(1, 'Exams and quizzes', 'exams/student-exams', null, 'card_membership', null, false, 0),
          new Menu(1, 'Mock exams', 'mocks/student-mocks-exams', null, 'poll', null, false, 0),
          //new Menu(2, 'Surveys', 'surveys/assigned-surveys', null, 'poll', null, false, 0),
        ]
      }
      else if (currentUserRole == "SurveyCreator") {
        menuArray = [
          new Menu(1, 'Surveys', '/surveys', null, 'poll', null, false, 0),
          new Menu(2, 'Assign management', 'surveys/assign-management', null, 'account_balance', null, false, 0),
          new Menu(3, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(4, 'Surveys analysis', 'ridwan/survey-analysis', null, 'summarize', null, false, 3)
        ]
      }
      else if (currentUserRole == "EvaluationCreator") {
        menuArray = [
          new Menu(1, 'Evaluations', '/evaluations', null, 'poll', null, false, 0),
          new Menu(3, 'Assign management', 'evaluations/assign-management', null, 'account_balance', null, false, 0),
          new Menu(4, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(5, 'Supervisor evaluation', 'ridwan/supervisor-evaluation', null, 'summarize', null, false, 4),
        ]
      }
      else if (currentUserRole == "EvaluationAssigner") {
        menuArray = [
          new Menu(1, 'Published evaluations', 'evaluations/published-evaluations', null, 'poll', null, false, 0),
          new Menu(2, 'Assign management', 'evaluations/assign-management', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "SurveyAssigner") {
        menuArray = [
          new Menu(1, 'Published surveys', 'surveys/published-surveys', null, 'poll', null, false, 0),
          new Menu(2, 'Assign management', 'surveys/assign-management', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "SubscriptionAdmin") {
        menuArray = [
          new Menu(3, 'School Management', null, null, 'computer', null, true, 0),
          new Menu(4, 'Schools', 'setups/services-management', null, 'keyboard', null, false, 3),
          new Menu(5, 'Curriculums', 'setups/curriculums-management', null, 'keyboard', null, false, 3),
          new Menu(6, 'Communication Policy', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(7, 'Attendance', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(8, 'Behaviors', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(9, 'Marks', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(10, 'Lesson Plan', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(11, 'Weekly Plan Template', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(12, 'School Bag', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(13, 'Roles & Permissions', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(13, 'Constants', 'setups/constants', null, 'keyboard', null, false, 3),
          new Menu(14, 'Reports Menu', 'exams/menu-index', null, 'summarize', null, false, 3),
          new Menu(14, 'Reports Management', 'setups/reports-management', null, 'summarize', null, false, 3),
          new Menu(15, 'Users Management', null, null, 'computer', null, true, 0),
          new Menu(16, 'Import Users', '/ui/buttons', null, 'keyboard', null, false, 15),
          new Menu(17, 'Students', 'setups/grades-students-management', null, 'keyboard', null, false, 15),
          new Menu(18, 'Staff ', 'setups/staff-management', null, 'keyboard', null, false, 15),
          new Menu(19, 'Semesters Management', null, null, 'computer', null, true, 0),
          new Menu(20, 'Semesters', 'setups/semesters-management', null, 'keyboard', null, false, 19),
          new Menu(21, 'Classes', 'setups/grades-divisions-management', null, 'keyboard', null, false, 19),
          new Menu(22, 'Sectioning', null, null, 'computer', null, true, 19),
          new Menu(23, 'Student Sectioning', 'setups/students-sectioning', null, 'keyboard', null, false, 22),
          new Menu(24, 'Teaching Sectioning', 'setups/teaching-sectioning', null, 'keyboard', null, false, 22),
          new Menu(25, 'Supervisors Sectioning', 'setups/supervisors-sectioning', null, 'keyboard', null, false, 22),
          new Menu(26, 'Sectioning Dashboard', '/ui/buttons', null, 'keyboard', null, false, 22),
          new Menu(27, 'Timetable', '/ui/buttons', null, 'keyboard', null, false, 18),
        ]
      }
      else if (currentUserRole == "BankCreator") {
        menuArray = [
          new Menu(1, 'Questions Bank', 'exams/questions-bank', null, 'account_balance', null, false, 0),
        ]
      }
    }
    else if (location.hostname == 'ridwan.edulinkin.com' ||
      location.hostname == 'ridwanprod.edulinkin.com' ||
      location.hostname == 'ridwanstage.edulinkin.com' ||
      location.hostname == 'exam.edulinkin.com' ||
      location.hostname == 'demo.edulinkin.com' ||
      location.hostname == 'localhost') {
      if (currentUserRole == "Teacher") {
        menuArray = [
          new Menu(1, 'Exams', '/exams', null, 'account_balance', null, false, 0),
          new Menu(3, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
          new Menu(4, 'Surveys', 'surveys/assigned-surveys', null, 'poll', null, false, 0),
          new Menu(5, 'Evaluations', 'evaluations/targeted-evaluations', null, 'poll', null, false, 0),
        ]
      }
      else if (currentUserRole == "SubTeacher") {
        menuArray = [
          new Menu(1, 'Exams', 'exams/published-exams', null, 'card_membership', null, false, 0),
          new Menu(2, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "Supervisor") {
        menuArray = [
          new Menu(1, 'Exams', '/exams', null, 'account_balance', null, false, 0),
          new Menu(4, 'Surveys', 'surveys/assigned-surveys', null, 'poll', null, false, 0),
          new Menu(2, 'Evaluations', 'evaluations/assigned-evaluations', null, 'poll', null, false, 0),
          new Menu(2, 'Evaluations to targets', 'evaluations/assigned-targeted-evaluations', null, 'poll', null, false, 0),
          new Menu(4, 'Evaluations correction', 'evaluations/published-evaluations-correction', null, 'account_balance', null, false, 0),
          new Menu(3, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
          new Menu(4, 'Published exams correction', 'exams/published-exams-correction', null, 'account_balance', null, false, 0),
          new Menu(5, 'Exam index', 'exams/exam-index', null, 'summarize', null, false, 0),
          new Menu(5, 'Subject index', 'exams/subject-index', null, 'summarize', null, false, 0),
          new Menu(6, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(7, 'Supervisor evaluation', 'ridwan/supervisor-evaluation', null, 'summarize', null, false, 6),
          // new Menu(7, 'Results by categories', 'exams/results-by-categories', null, 'summarize', null, false, 6),
          // new Menu(8, 'Ranges on assessment', 'exams/ranges-on-assessment', null, 'summarize', null, false, 6),
          // new Menu(9, 'Ranges on skills', 'exams/ranges-on-skills', null, 'summarize', null, false, 6),
          // new Menu(10, 'Ranges on gender', 'exams/ranges-on-gender', null, 'summarize', null, false, 6),
          // new Menu(11, 'Ranges on nationality', 'exams/ranges-on-nationality', null, 'summarize', null, false, 6),
          // new Menu(12, 'Students results', 'exams/students-results', null, 'summarize', null, false, 6),
          // new Menu(12, 'Question answers count', 'exams/questions-answers-count', null, 'summarize', null, false, 6)
        ]
      }
      else if (currentUserRole == "SubSupervisor") {
        menuArray = [
          new Menu(1, 'Published exams', 'exams/published-exams', null, 'account_balance', null, false, 0),
          new Menu(2, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
          new Menu(5, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(6, 'Results by categories', 'exams/results-by-categories', null, 'summarize', null, false, 5),
          new Menu(7, 'Ranges on assessment', 'exams/ranges-on-assessment', null, 'summarize', null, false, 5),
          new Menu(8, 'Ranges on skills', 'exams/ranges-on-skills', null, 'summarize', null, false, 5),
          new Menu(9, 'Ranges on gender', 'exams/ranges-on-gender', null, 'summarize', null, false, 5),
          new Menu(10, 'Ranges on nationality', 'exams/ranges-on-nationality', null, 'summarize', null, false, 5),
          new Menu(11, 'Students results', 'exams/students-results', null, 'summarize', null, false, 5),
          new Menu(12, 'Question answers count', 'exams/questions-answers-count', null, 'summarize', null, false, 5)
        ]
      }
      else if (currentUserRole == "Parent") {
        menuArray = [
          new Menu(1, 'Surveys', 'surveys/assigned-surveys', null, 'poll', null, false, 0),
          new Menu(2, 'Dentist form', 'ridwan/dentist-form', null, 'poll', null, false, 0),
        ]
      }
      else if (currentUserRole == "Student") {
        menuArray = [
          new Menu(1, 'Exams and quizzes', 'exams/student-exams', null, 'card_membership', null, false, 0),
          new Menu(2, 'Surveys', 'surveys/assigned-surveys', null, 'poll', null, false, 0),
        ]
      }
      else if (currentUserRole == "SurveyCreator") {
        menuArray = [
          new Menu(1, 'Surveys', '/surveys', null, 'poll', null, false, 0),
          new Menu(2, 'Assign management', 'surveys/assign-management', null, 'account_balance', null, false, 0),
          new Menu(3, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(4, 'Surveys analysis', 'ridwan/survey-analysis', null, 'summarize', null, false, 3),
        ]
      }
      else if (currentUserRole == "EvaluationCreator") {
        menuArray = [
          new Menu(1, 'Evaluations', '/evaluations', null, 'poll', null, false, 0),
          new Menu(3, 'Assign management', 'evaluations/assign-management', null, 'account_balance', null, false, 0),
          new Menu(4, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(5, 'Supervisor evaluation', 'ridwan/supervisor-evaluation', null, 'summarize', null, false, 4),
        ]
      }
      else if (currentUserRole == "EvaluationAssigner") {
        menuArray = [
          new Menu(1, 'Published evaluations', 'evaluations/published-evaluations', null, 'poll', null, false, 0),
          new Menu(2, 'Assign management', 'evaluations/assign-management', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "SurveyAssigner") {
        menuArray = [
          new Menu(1, 'Published surveys', 'surveys/published-surveys', null, 'poll', null, false, 0),
          new Menu(2, 'Assign management', 'surveys/assign-management', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "SubscriptionAdmin") {
        menuArray = [
          new Menu(3, 'School Management', null, null, 'computer', null, true, 0),
          new Menu(4, 'Schools', 'setups/services-management', null, 'keyboard', null, false, 3),
          new Menu(5, 'Curriculums', 'setups/curriculums-management', null, 'keyboard', null, false, 3),
          new Menu(6, 'Communication Policy', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(7, 'Attendance', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(8, 'Behaviors', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(9, 'Marks', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(10, 'Lesson Plan', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(11, 'Weekly Plan Template', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(12, 'School Bag', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(13, 'Roles & Permissions', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(13, 'Constants', 'setups/constants', null, 'keyboard', null, false, 3),
          new Menu(14, 'Reports Menu', 'exams/menu-index', null, 'summarize', null, false, 3),
          new Menu(15, 'Users Management', null, null, 'computer', null, true, 0),
          new Menu(16, 'Import Users', '/ui/buttons', null, 'keyboard', null, false, 15),
          new Menu(17, 'Students', 'setups/grades-students-management', null, 'keyboard', null, false, 15),
          new Menu(18, 'Staff ', 'setups/staff-management', null, 'keyboard', null, false, 15),
          new Menu(19, 'Semesters Management', null, null, 'computer', null, true, 0),
          new Menu(20, 'Semesters', 'setups/semesters-management', null, 'keyboard', null, false, 19),
          new Menu(21, 'Classes', 'setups/grades-divisions-management', null, 'keyboard', null, false, 19),
          new Menu(22, 'Sectioning', null, null, 'computer', null, true, 19),
          new Menu(23, 'Student Sectioning', 'setups/students-sectioning', null, 'keyboard', null, false, 22),
          new Menu(24, 'Teaching Sectioning', 'setups/teaching-sectioning', null, 'keyboard', null, false, 22),
          new Menu(25, 'Supervisors Sectioning', 'setups/supervisors-sectioning', null, 'keyboard', null, false, 22),
          new Menu(26, 'Sectioning Dashboard', '/ui/buttons', null, 'keyboard', null, false, 22),
          new Menu(27, 'Timetable', '/ui/buttons', null, 'keyboard', null, false, 18),
        ]
      }
      else if (currentUserRole == "BankCreator") {
        menuArray = [
          new Menu(1, 'Questions Bank', 'exams/questions-bank', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "Doctor") {
        menuArray = [
          new Menu(1, 'Dentist', 'ridwan/dentist-form', null, 'poll', null, false, 0),
        ]
      }
    }
    else if (
      location.hostname == 'alpha.edulinkin.com' ||
      location.hostname == 'beta.edulinkin.com' ||
      location.hostname == 'release.edulinkin.com') {
      if (currentUserRole == "Teacher") {
        menuArray = [
          new Menu(1, 'Exams', '/exams', null, 'account_balance', null, false, 0),
          new Menu(3, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
          new Menu(4, 'Surveys', 'surveys/assigned-surveys', null, 'poll', null, false, 0),
          new Menu(5, 'Evaluations', 'evaluations/targeted-evaluations', null, 'poll', null, false, 0),
        ]
      }
      else if (currentUserRole == "SubTeacher") {
        menuArray = [
          new Menu(1, 'Exams', 'exams/published-exams', null, 'card_membership', null, false, 0),
          new Menu(2, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "Supervisor") {
        menuArray = [
          new Menu(1, 'Exams', '/exams', null, 'account_balance', null, false, 0),
          new Menu(4, 'Surveys', 'surveys/assigned-surveys', null, 'poll', null, false, 0),
          new Menu(2, 'Evaluations', 'evaluations/assigned-evaluations', null, 'poll', null, false, 0),
          new Menu(2, 'Evaluations to targets', 'evaluations/assigned-targeted-evaluations', null, 'poll', null, false, 0),
          new Menu(3, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
          new Menu(4, 'Published exams correction', 'exams/published-exams-correction', null, 'account_balance', null, false, 0),
          new Menu(5, 'Exam index', 'exams/exam-index', null, 'summarize', null, false, 0),
          new Menu(5, 'Subject index', 'exams/subject-index', null, 'summarize', null, false, 0),
          new Menu(6, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(7, 'Supervisor evaluation', 'ridwan/supervisor-evaluation', null, 'summarize', null, false, 6),
        ]
      }
      else if (currentUserRole == "SubSupervisor") {
        menuArray = [
          new Menu(1, 'Published exams', 'exams/published-exams', null, 'account_balance', null, false, 0),
          new Menu(2, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
          new Menu(5, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(6, 'Results by categories', 'exams/results-by-categories', null, 'summarize', null, false, 5),
          new Menu(7, 'Ranges on assessment', 'exams/ranges-on-assessment', null, 'summarize', null, false, 5),
          new Menu(8, 'Ranges on skills', 'exams/ranges-on-skills', null, 'summarize', null, false, 5),
          new Menu(9, 'Ranges on gender', 'exams/ranges-on-gender', null, 'summarize', null, false, 5),
          new Menu(10, 'Ranges on nationality', 'exams/ranges-on-nationality', null, 'summarize', null, false, 5),
          new Menu(11, 'Students results', 'exams/students-results', null, 'summarize', null, false, 5),
          new Menu(12, 'Question answers count', 'exams/questions-answers-count', null, 'summarize', null, false, 5)
        ]
      }
      else if (currentUserRole == "Parent") {
        menuArray = [
          new Menu(1, 'Surveys', 'surveys/assigned-surveys', null, 'poll', null, false, 0),
          new Menu(2, 'Dentist form', 'ridwan/dentist-form', null, 'poll', null, false, 0),
        ]
      }
      else if (currentUserRole == "Student") {
        menuArray = [
          new Menu(1, 'Exams and quizzes', 'exams/student-exams', null, 'card_membership', null, false, 0),
          new Menu(2, 'Surveys', 'surveys/assigned-surveys', null, 'poll', null, false, 0),
        ]
      }
      else if (currentUserRole == "SurveyCreator") {
        menuArray = [
          new Menu(1, 'Surveys', '/surveys', null, 'poll', null, false, 0),
          new Menu(2, 'Assign management', 'surveys/assign-management', null, 'account_balance', null, false, 0),
          new Menu(3, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(4, 'Surveys analysis', 'ridwan/survey-analysis', null, 'summarize', null, false, 3),
        ]
      }
      else if (currentUserRole == "EvaluationCreator") {
        menuArray = [
          new Menu(1, 'Evaluations', '/evaluations', null, 'poll', null, false, 0),
          new Menu(3, 'Assign management', 'evaluations/assign-management', null, 'account_balance', null, false, 0),
          new Menu(4, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(5, 'Supervisor evaluation', 'ridwan/supervisor-evaluation', null, 'summarize', null, false, 4),
        ]
      }
      else if (currentUserRole == "EvaluationAssigner") {
        menuArray = [
          new Menu(1, 'Published evaluations', 'evaluations/published-evaluations', null, 'poll', null, false, 0),
          new Menu(2, 'Assign management', 'evaluations/assign-management', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "SurveyAssigner") {
        menuArray = [
          new Menu(1, 'Published surveys', 'surveys/published-surveys', null, 'poll', null, false, 0),
          new Menu(2, 'Assign management', 'surveys/assign-management', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "SubscriptionAdmin") {
        menuArray = [
          new Menu(3, 'School Management', null, null, 'computer', null, true, 0),
          new Menu(4, 'Schools', 'setups/services-management', null, 'keyboard', null, false, 3),
          new Menu(5, 'Curriculums', 'setups/curriculums-management', null, 'keyboard', null, false, 3),
          new Menu(6, 'Communication Policy', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(7, 'Attendance', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(8, 'Behaviors', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(9, 'Marks', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(10, 'Lesson Plan', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(11, 'Weekly Plan Template', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(12, 'School Bag', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(13, 'Roles & Permissions', '/ui/buttons', null, 'keyboard', null, false, 3),
          new Menu(13, 'Constants', 'setups/constants', null, 'keyboard', null, false, 3),
          new Menu(14, 'Reports Menu', 'exams/menu-index', null, 'summarize', null, false, 3),
          new Menu(15, 'Users Management', null, null, 'computer', null, true, 0),
          new Menu(16, 'Import Users', '/ui/buttons', null, 'keyboard', null, false, 15),
          new Menu(17, 'Students', 'setups/grades-students-management', null, 'keyboard', null, false, 15),
          new Menu(18, 'Staff ', 'setups/staff-management', null, 'keyboard', null, false, 15),
          new Menu(19, 'Semesters Management', null, null, 'computer', null, true, 0),
          new Menu(20, 'Semesters', 'setups/semesters-management', null, 'keyboard', null, false, 19),
          new Menu(21, 'Classes', 'setups/grades-divisions-management', null, 'keyboard', null, false, 19),
          new Menu(22, 'Sectioning', null, null, 'computer', null, true, 19),
          new Menu(23, 'Student Sectioning', 'setups/students-sectioning', null, 'keyboard', null, false, 22),
          new Menu(24, 'Teaching Sectioning', 'setups/teaching-sectioning', null, 'keyboard', null, false, 22),
          new Menu(25, 'Supervisors Sectioning', 'setups/supervisors-sectioning', null, 'keyboard', null, false, 22),
          new Menu(26, 'Sectioning Dashboard', '/ui/buttons', null, 'keyboard', null, false, 22),
          new Menu(27, 'Timetable', '/ui/buttons', null, 'keyboard', null, false, 18),
        ]
      }
      else if (currentUserRole == "BankCreator") {
        menuArray = [
          new Menu(1, 'Questions Bank', 'exams/questions-bank', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "Doctor") {
        menuArray = [
          new Menu(1, 'Dentist', 'ridwan/dentist-form', null, 'poll', null, false, 0),
        ]
      }
    }

    return menuArray;
  }

  public getHorizontalMenuItems(): Array<Menu> {
    let currentUserRole = localStorage.getItem('currentUserRole');
    let menuArray = [];

    // if (currentUserRole == "Teacher") {
    //   menuArray = [
    //     new Menu(1, 'Exams', '/exams', null, 'account_balance', null, false, 0),
    //     new Menu(3, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
    //   ]
    // }
    // else if (currentUserRole == "Supervisor") {
    //   menuArray = [
    //     new Menu(1, 'Exams', '/exams', null, 'account_balance', null, false, 0),
    //     new Menu(2, 'Published exams', 'exams/published-exams', null, 'account_balance', null, false, 0),
    //     new Menu(3, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
    //     new Menu(4, 'Published exams correction', 'exams/published-exams-correction', null, 'account_balance', null, false, 0),
    //     new Menu(5, 'Results by categories', 'exams/results-by-categories', null, 'account_balance', null, false, 0),
    //   ]
    // }
    // else if (currentUserRole == "SubTeacher") {
    //   menuArray = [
    //     new Menu(1, 'Exams', 'exams/published-exams', null, 'card_membership', null, false, 0),
    //     new Menu(2, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
    //   ]
    // }
    // else if (currentUserRole == "Student") {
    //   menuArray = [
    //     new Menu(1, 'Exams and quizzes', 'exams/student-exams', null, 'card_membership', null, false, 0),
    //   ]
    // }
    // else if (currentUserRole == "SubscriptionAdmin") {
    //   menuArray = [
    //     new Menu(3, 'School Management', null, null, 'computer', null, true, 0),
    //     new Menu(4, 'Schools', 'setups/services-management', null, 'keyboard', null, false, 3),
    //     new Menu(5, 'Curriculums', 'setups/curriculums-management', null, 'keyboard', null, false, 3),
    //     new Menu(6, 'Communication Policy', '/ui/buttons', null, 'keyboard', null, false, 3),
    //     new Menu(7, 'Attendance', '/ui/buttons', null, 'keyboard', null, false, 3),
    //     new Menu(8, 'Behaviors', '/ui/buttons', null, 'keyboard', null, false, 3),
    //     new Menu(9, 'Marks', '/ui/buttons', null, 'keyboard', null, false, 3),
    //     new Menu(10, 'Lesson Plan', '/ui/buttons', null, 'keyboard', null, false, 3),
    //     new Menu(11, 'Weekly Plan Template', '/ui/buttons', null, 'keyboard', null, false, 3),
    //     new Menu(12, 'School Bag', '/ui/buttons', null, 'keyboard', null, false, 3),
    //     new Menu(13, 'Roles & Permissions', '/ui/buttons', null, 'keyboard', null, false, 3),
    //     new Menu(14, 'Users Management', null, null, 'computer', null, true, 0),
    //     new Menu(15, 'Import Users', '/ui/buttons', null, 'keyboard', null, false, 14),
    //     new Menu(16, 'Students', 'setups/grades-students-management', null, 'keyboard', null, false, 14),
    //     new Menu(17, 'Staff ', 'setups/staff-management', null, 'keyboard', null, false, 14),
    //     new Menu(18, 'Semesters Management', null, null, 'computer', null, true, 0),
    //     new Menu(19, 'Semesters', 'setups/semesters-management', null, 'keyboard', null, false, 18),
    //     new Menu(20, 'Classes', 'setups/grades-divisions-management', null, 'keyboard', null, false, 18),
    //     new Menu(21, 'Sectioning', null, null, 'computer', null, true, 18),
    //     new Menu(22, 'Student Sectioning', 'setups/students-sectioning', null, 'keyboard', null, false, 21),
    //     new Menu(23, 'Teaching Sectioning', 'setups/teaching-sectioning', null, 'keyboard', null, false, 21),
    //     new Menu(24, 'Supervisors Sectioning', 'setups/supervisors-sectioning', null, 'keyboard', null, false, 21),
    //     new Menu(25, 'Sectioning Dashboard', '/ui/buttons', null, 'keyboard', null, false, 21),
    //     new Menu(26, 'Timetable', '/ui/buttons', null, 'keyboard', null, false, 18),

    //   ]
    // }
    return menuArray;
  }

  public expandActiveSubMenu(menu: Array<Menu>) {
    let url = this.location.path();
    let routerLink = url; // url.substring(1, url.length);
    let activeMenuItem = menu.filter(item => item.routerLink === routerLink);
    if (activeMenuItem[0]) {
      let menuItem = activeMenuItem[0];
      while (menuItem.parentId != 0) {
        let parentMenuItem = menu.filter(item => item.id == menuItem.parentId)[0];
        menuItem = parentMenuItem;
        this.toggleMenuItem(menuItem.id);
      }
    }
  }

  public toggleMenuItem(menuId) {
    let menuItem = document.getElementById('menu-item-' + menuId);
    let subMenu = document.getElementById('sub-menu-' + menuId);
    if (subMenu) {
      if (subMenu.classList.contains('show')) {
        subMenu.classList.remove('show');
        menuItem.classList.remove('expanded');
      }
      else {
        subMenu.classList.add('show');
        menuItem.classList.add('expanded');
      }
    }
  }

  public closeOtherSubMenus(menu: Array<Menu>, menuId) {
    let currentMenuItem = menu.filter(item => item.id == menuId)[0];
    if (currentMenuItem.parentId == 0 && !currentMenuItem.target) {
      menu.forEach(item => {
        if (item.id != menuId) {
          let subMenu = document.getElementById('sub-menu-' + item.id);
          let menuItem = document.getElementById('menu-item-' + item.id);
          if (subMenu) {
            if (subMenu.classList.contains('show')) {
              subMenu.classList.remove('show');
              menuItem.classList.remove('expanded');
            }
          }
        }
      });
    }
  }

  public closeAllSubMenus() {
    let menu = document.getElementById("vertical-menu");
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }


}
